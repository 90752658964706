import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Box, Text } from 'rebass/styled-components';
import PageLayout from '../components/PageLayout';
import { Footer } from '../components/Footer';
import TermsPrivacy from '../components/terms/TermsPrivacy';
export const metadata = {
  title: 'Terms'
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  metadata,
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <TermsPrivacy mdxType="TermsPrivacy" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      