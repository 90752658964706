import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Card, Flex, FlexProps, Text, TextProps } from 'rebass/styled-components';
import { SectionBox, TitleMD, SubtitleMD } from '../base';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SectionBox bg="primary.main" pb={4} mdxType="SectionBox">
      <TitleMD mdxType="TitleMD">Terms of Service and Privacy Policy</TitleMD>
    </SectionBox>
    <SectionBox bg="primary.text" mdxType="SectionBox">
      <SubtitleMD mdxType="SubtitleMD">Choice Limited</SubtitleMD>
      <SubtitleMD mdxType="SubtitleMD">User Terms and Conditions</SubtitleMD>
      <h3>{`Thanks for taking the time to check out our legal terms. This is where you'll find info about what you can and can't do with Choice and how we manage your use of our Services. These terms and conditions govern your use of the Choice Platform.`}</h3>
      <Box color="primary.main" mdxType="Box">
        <h4>{`Please read the below summary of the most important parts of this Agreement`}</h4>
      </Box>
      <ul>
        <li parentName="ul">{`This is a functional Beta software release intended for live testing purposes. Choice will inform you if there are planned updates or outages to the service.`}</li>
        <li parentName="ul">{`Choice lets you pay merchants directly through your mobile phone by scanning a Choice QR code in-store (displayed on a terminal or merchant phone).`}</li>
        <li parentName="ul">{`Choice charges merchants a fair and transparent flat rate fee to process your payment to the Merchant, and Choice then donates 50% of this fee to a New Zealand charity. `}</li>
        <li parentName="ul">{`Choice makes payments on your behalf from your Choice account securely to the Merchant.`}</li>
        <li parentName="ul">{`To use Choice, you are required to create an account and accept these Terms and Conditions.`}</li>
        <li parentName="ul">{`To enable us to comply with the Anti-Money Laundering and Countering Financing of
Terrorism Act 2009 in New Zealand, you will need  to share your e-mail, New Zealand phone number, and full legal name with us.`}</li>
        <li parentName="ul">{`We take all reasonable steps to secure your information by keeping it on encrypted servers with permissioned access.`}</li>
        <li parentName="ul">{`To pay with Choice, you will need to deposit funds (not exceeding $150) into our Commercial Trust Account with a special reference code generated for you. We cannot allow any User to hold more than $150.00 in your account at this time to ensure we comply with the Anti-Money Laundering and Countering Financing of Terrorism Act 2009 in New Zealand.`}</li>
        <li parentName="ul">{`Funds will be credited to your Choice account once cleared via New Zealand bank deposit. This typically takes between2 and 12 hours (estimated) on a weekday, and if a deposit is made on a weekend, it will be credited the following Monday morning.`}</li>
        <li parentName="ul">{`Choice can not offer refunds on unspent balances at this time`}{`[, to ensure we comply with Anti-Money Laundering and Countering Financing of Terrorism Act 2009 in New Zealand.]`}</li>
        <li parentName="ul">{`If someone can unlock your mobile phone, they may be able to make unauthorised payments on your Choice application to Choice merchants. For your safety, Choice will not activate on your phone if you do not have it lock enabled (PIN or biometric). We strongly recommend you keep your mobile phone lock details confidential. Choice is not liable for unauthorised payments that result from someone unlocking your phone and using your Choice application.`}</li>
        <li parentName="ul">{`Choice Limited will guarantee if the Choice Platform ceases to operate for any reason that every User will be contacted and have their total unspent account balance reimbursed to the bank account from where it originated.`}</li>
      </ul>
      <h2>{`1. Terms and Conditions for use of Choice Platform`}</h2>
      <p>{`1.1 Acceptance of Terms and Conditions: These terms and conditions are the terms on which
access to the Choice Platform is provided and you use and receive the benefit of the mobile payment processing service provided by Choice. By registering to become a Choice Registered User with Choice,
you accept these terms and conditions. If you do not accept these terms and conditions, you must not use the Choice Platform.`}</p>
      <p>{`1.2 Variation to Terms and Conditions: These terms and conditions may be varied by Choice from time to time by posting a notice of change on Choice’s website. Notification will also be made to the email address recorded in your Choice Registered User account. The variation to the terms and conditions will take effect 10 Business Days after the notice was posted on Choice’s website. By continuing to use the Choice Platform after the 10 Business Day notice period, you agree to be bound by the varied terms and conditions as published.`}</p>
      <p>{`1.3 Assignment:`}</p>
      <p>{`1.3.1 You may not assign your rights and obligations under these terms and conditions without the prior written
consent of Choice.`}</p>
      <p>{`1.3.2 Choice can assign its rights and obligations under these terms and conditions to any person at any time.
Notification of such assignment will be made by posting a notice on Choice’s website. The assignment will take
effect 10 Business Days after posting on Choice’s website. By continuing to use the Choice Platform after the 10
Business Day notice period, you agree to be bound by the assignment.`}</p>
      <p>{`1.4 Becoming a Choice Registered User: Becoming a Choice Registered User is free. It forms a legally binding contract which is enforceable against you.`}</p>
      <p>{`1.5 Governing Law: These terms and conditions are governed by New Zealand law. The New Zealand courts shall have non-exclusive jurisdiction in relation to any proceedings regarding these terms and conditions.`}</p>
      <h2>{`2 Service provided by Choice`}</h2>
      <p>{`2.1 The Service: Choice provides a mobile payments processing facility for users and merchants (instead of using credit or debit cards):`}</p>
      <p>{`2.1.1 On the customer side, the customer downloads the Choice mobile app onto his or her mobile phone, signs up with a verified e-mail address and identity, and deposits funds into the Choice Limited Trust Account. Choice will credit this amount to the User’s account within one Business day. When going to pay for a product or service, the customer scans a Choice QR code to pay at the counter, and authorises the payment to the Merchant. Account limits are set at $150 NZD to maintain Choice as a low-limit store of value. Choice cannot offer refunds on unspent balances at this time.`}</p>
      <p>{`2.1.2 The Choice Platform provides a payment processing service, which pays the Merchant the purchase price of
the goods or services, less a nominated transaction fee (50% of which is paid to a local charity nominated by Choice).`}</p>
      <p>{`This is explained in more detail throughout the agreement.`}</p>
      <p>{`2.2 Choice is not party to Customer / Merchant contract:`}</p>
      <p>{`2.2.1 Choice does not take any part in the contract for sale and purchase of goods or services between you and a
Merchant registered with Choice. The Choice Platform merely provides a means of communicating orders and
facilitating payment for the goods or services purchased.`}</p>
      <p>{`2.2.2 Choice accepts no responsibility or liability for any issues which may arise between you and a Merchant.
Responsibility for any refund or default rests with you.`}</p>
      <p>{`2.2.3 If you have a dispute regarding goods or services purchased from you, you must resolve the dispute directly
with the Merchant. Any reimbursement for goods and services must be arranged between you and the Merchant
directly – it cannot be re-credited back to your bank account.`}</p>
      <p>{`2.3 Choice’s right to cancel the Choice Platform: Choice reserves the right to cancel the Choice Platform at any time.`}</p>
      <p>{`2.4 Choice Beta: Choice may invite You to trial the Choice Platform while still in development (whether described as beta, pilot, non-production, limited release, evaluation or otherwise) (Choice Beta), at no charge, for a limited period that we agree in writing. You may accept (by continuing to use Choice Beta) or decline (by not using Choice Beta) in Your sole discretion. If You accept, You acknowledge and agree that:`}</p>
      <p>{`2.4.1 Choice Beta is for evaluation purposes (not production use), it is provided “as is where is”,
it is not considered the Choice Platform under this Agreement, and that Choice will have no liability for any harm or
damage arising out of or in connection with Your use of Choice Beta; and`}</p>
      <p>{`2.4.2 Choice can assign its rights and obligations under these terms and conditions to any person at any time.
Notification of such assignment will be made by posting a notice on Choice’s website. The assignment will take effect
10 Business Days after posting on Choice’s website. By continuing to use the Choice Platform after the 10 Business
Day notice period, you agree to be bound by the assignment.`}</p>
      <p>{`2.5 Dispute Resolution: Choice suggests you contact us first and we will do all we can to resolve your dispute as soon as possible:`}</p>
      <Card borderRadius={10} borderColor="dark" boxShadow="0 2px 16px rgba(0, 0, 0, 0.25)" p={4} width={[1, 1, 1 / 2]} alignSelf="right" mdxType="Card">
  <Text mdxType="Text">Phone:  &nbsp;+64 27 358 8333</Text>
  <Text css={{
          display: 'inline-block'
        }} mdxType="Text">Email:</Text>
  <Text css={{
          display: 'inline-block'
        }} color="primary.main" mdxType="Text">
          <p><a parentName="p" {...{
              "href": "mailto:info@choicetopay.com"
            }}>{`info@choicetopay.com`}</a></p>
        </Text>
      </Card>
      <p>{`If Choice cannot assist you with resolving the dispute, Choice is member of the Financial Services Complaints Limited dispute resolution scheme. You may refer any dispute relating to the provision of the Service to that dispute resolution service, details of which are as follows:`}</p>
      <Card borderRadius={10} borderColor="dark" boxShadow="0 2px 16px rgba(0, 0, 0, 0.25)" p={4} width={[1, 1, 1 / 2]} alignSelf="right" mdxType="Card">
  <Text mdxType="Text">Phone:  &nbsp;0800 347 257 or 04 472 3725</Text>
  <Text css={{
          display: 'inline-block'
        }} mdxType="Text">Email:</Text>
  <Text css={{
          display: 'inline-block'
        }} color="primary.main" mdxType="Text">
          <p><a parentName="p" {...{
              "href": "mailto:info@fscl.org.nz"
            }}>{`info@fscl.org.nz`}</a></p>
  </Text>
  <Text mdxType="Text">Post:  &nbsp;Financial Services Complaints Limited, 
        PO Box 5967, Lambton Quay, Wellington 6145
  </Text>
  <Text css={{
          display: 'inline-block'
        }} mdxType="Text">Website:</Text>
  <Text css={{
          display: 'inline-block'
        }} color="primary.main" mdxType="Text">
          <p><a parentName="p" {...{
              "href": "https://www.fscl.org.nz"
            }}>{`info@fscl.org.nz`}</a></p>
        </Text>
      </Card>
      <p>{`Choice will not resolve any dispute you may have with the relevant Merchant regarding any goods or services you have purchased using the Service. Please contact the relevant Merchant directly.`}</p>
      <h2>{`3 User obligations`}</h2>
      <p>{`3.1 Your obligations: You will:`}</p>
      <p>{`3.1.1 Keep your Choice Platform login information, including your pins and password, secret and secure.`}</p>
      <p>{`3.1.2 Take all reasonable steps to keep your device secure and free from any unauthorised access or computer virus.`}</p>
      <p>{`3.1.3 Meet all of your obligations under all applicable consumer legislation.`}</p>
      <p>{`3.1.4 Not use the Choice Platform for any dishonest, fraudulent or otherwise illegal purpose.`}</p>
      <p>{`3.1.5 Immediately notify Choice of any suspicious, fraudulent or unauthorised transactions.`}</p>
      <p>{`3.1.6 Comply with all other applicable laws and regulations.`}</p>
      <h2>{`4 Choice’s rights and obligations`}</h2>
      <p>{`4.1 Choice’s obligations: Choice will process transactions, provided that:`}</p>
      <p>{`4.1.1 There is sufficient credit in your relevant User account to pay for the purchase from the Merchant.`}</p>
      <p>{`4.1.2 Choice has received all necessary documentation from the Merchant
(or you on the Merchant’s behalf) evidencing their trade with you.`}</p>
      <p>{`4.1.3 The transaction verification process has been completed and Choice is satisfied (in its sole discretion)
that the trade is a genuine transaction.`}</p>
      <p>{`4.2 Suspending or cancelling transactions: Choice can in its sole discretion suspend or cancel a transaction through the Choice Platform if Choice has any reason to suspect the transaction involves fraud, money laundering or financing of terrorism. Choice will notify you by email if a transaction is suspended or cancelled.`}</p>
      <p>{`4.3 Preventing use of Choice Platform by User: Choice reserves the right to suspend or terminate the membership of a User or any other person from using the Choice Platform at its sole discretion.`}</p>
      <h2>{`5 Fees`}</h2>
      <p>{`5.1 Free to Use for Consumers: The Choice Platform is free for users. Choice charges merchants a flat rate transaction fee and donates 50% of that fee to a local charity. More detail on where this fee goes can be found in the Choice application.`}</p>
      <h2>{`6 Personal information`}</h2>
      <p>{`6.1 Accurate information: You warrant that you have provided complete, accurate and current information when registering as a User with Choice, and that you will immediately notify Choice of any changes to the details provided in your registration application.`}</p>
      <p>{`6.2 Requests for additional information: Choice may make enquiries about any personal information that you provide to Choice in order to check the accuracy of that information. You agree to provide all reasonable information (including any documentation) requested by Choice to assist Choice to meet its obligations under the Anti-Money Laundering and Countering Financing of Terrorism Act 2009 and any other relevant laws.`}</p>
      <p>{`6.3 Choice’s obligations: Choice will process transactions, provided that:`}</p>
      <p>{`6.3.1 You must maintain and update your information held by us to ensure it is kept current at all times. We
may call or email you to verify these details.`}</p>
      <p>{`6.3.2 We provide you with ways in which you can change, update or add to your information, however the
responsibility for updating the information rests solely with you.`}</p>
      <p>{`6.4 Privacy: In accordance with the provisions of the Privacy Act 1993, Choice may collect personal information about you (including your User registration details, Customer transactions with you and your use of the Choice Platform. Please see the Privacy Policy attached to these Terms and Conditions.`}</p>
      <p>{`6.5 Security of your information: You are responsible for keeping your login information, including your pins and passwords, secret and secure. In particular, you agree to not permit any other person to use your user login information and not to disclose, or provide to any other person your pins and passwords or any other information in connection with your Choice User account that may allow them to gain access to your account.`}</p>
      <h2>{`7 Use of Choice intellectual property`}</h2>
      <p>{`7.1 Right to Display: You may use Choice’s business name, trademark, logo or website domain for the purpose of promoting the Choice Platform and its services and confirming you are a Choice Registered User.`}</p>
      <p>{`7.2 User obligations: You will:`}</p>
      <p>{`7.2.1 Not, without Choice’s prior written permission, in any form or by any means:`}</p>
      <p>{`a. Adapt, reproduce, copy, store, distribute, print, display, perform, publish or create derivative works from any
part of the Choice Platform or website; or`}</p>
      <p>{`b. Commercialise, copy, or on-sell any information, or items obtained from any part of the Choice Platform
or website.`}</p>
      <h5>{`7.3 Ownership of intellectual property:`}</h5>
      <p>{`7.3.1 You must maintain and update your information held by us to ensure it is kept current at all times. We may call or email you to verify these details.`}</p>
      <p>{`7.3.2 We provide you with ways in which you can change, update or add to your information, however the responsibility for updating the information rests solely with you.`}</p>
      <h2>{`8 No liability`}</h2>
      <p>{`8.1 Fraudulent activity: While Choice has used its best endeavours to ensure that appropriate systems are in place to reduce the risk of fraudulent activity, Choice is not responsible for protecting Choice Registered Users from forms of wrongful or fraudulent activity which may arise from unauthorised access to your device.`}</p>
      <p>{`8.2 Unauthorised access: Provided that Choice has taken reasonable steps to protect its systems, Choice does not accept any liability for any damage, loss, cost expense or harm arising directly or indirectly as a result of any computer virus or unauthorised access of our system.`}</p>
      <p>{`8.3 Inability to use Choice Platform: Choice accepts no responsibility for any inability to use the service that arises from an issue between you and your network or communication provider.`}</p>
      <p>{`8.4 Breach by User: Without limiting any other rights and remedies available to Choice, Choice may limit your use of the Choice Platform, terminate your Choice Registered User account, or refuse to provide Choice services to you if in Choice’s opinion you breach these terms and conditions.`}</p>
      <p>{`8.5 Liability: Notwithstanding any other terms of this agreement you agree that, to the maximum extent permitted by law, any and all liability and responsibility of Choice to you or any other person under or in connection with these terms and conditions, the Choice Platform, Choice’s website, any Choice Customer’s act or omission, or your use of or inability to use the Choice Platform, is excluded regardless of whether such liability arises in contract, tort (including negligence), equity, breach of statutory duty or otherwise, Choice’s liability and responsibility is excluded in respect of any and all loss or damage, whether direct or indirect, including, without limitation, loss of profits, loss of data, loss of business or anticipated savings, general and special damages, and consequential and incidental loss.`}</p>
      <p>{`8.6 Indemnity: By using this service, you agree to indemnify Choice from and against all actions, claims, costs (including legal costs and expenses on a solicitor and client basis), Choice to any person arising out of or in connection with your failure to comply with these terms and conditions or failure by you to complete a transaction with a Customer or out of or in connection with your use of the Choice Platform.`}</p>
      <p>{`8.7 Force Majeure: Choice has no liability for any lack of performance, or for any failure of Choice to comply with these terms and conditions where the same arises from any cause reasonably beyond Choice’s control.`}</p>
      <p>{`8.8 No Waiver: If Choice does not exercise or enforce any right available to us under these terms and conditions, it does not constitute a waiver of those rights.`}</p>
      <p>{`8.9 Consumer Guarantees Act: Nothing in this clause 8 or these terms limits any rights you may have under the Consumer Guarantees Act 1993, the Fair Trading Act 1986, or any other applicable legislation. This clause 39 is for the benefit of, and is enforceable by, each relevant person, in terms of Part 2, subpart 1 of the Contract and Commercial Law Act 2017...`}</p>
      <h2>{`9 Termination or suspension`}</h2>
      <p>{`9.1 By Choice: Choice may in its sole discretion suspend or terminate your Choice Registered User account and/or your use of the Choice Platform or Choice website immediately upon notice.`}</p>
      <p>{`9.2 By Merchant: You may at any time request that Choice suspend or terminate your Choice Registered User account and/or your use of the Choice Platform or Choice website.`}</p>
      <h5>{`9.3 Obligations on termination or suspension: Upon termination or suspension:`}</h5>
      <p>{`9.3.1 You will not hold out or represent that you are a Choice Registered User or have any relationship
with Choice.`}</p>
      <p>{`9.3.2 You will immediately cease using Choice’s Intellectual Property.`}</p>
      <p>{`9.3.3 You will immediately return to Choice, or destroy at Choice’s direction, any information or property
in your possession relating to the Choice Platform.`}</p>
      <h2>{`10 Notices`}</h2>
      <p>{`10.1 Notices: All notices and other communications will be made by us to the email address contained in your Choice Registered User account, and are deemed to be received by you at the time specified in the email transmission provided it was not returned as undeliverable or as containing any error.`}</p>
      <h2>{`11 Definitions and Interpretation`}</h2>
      <h5>{`11.1 Definitions: In these terms and conditions, unless the context requires otherwise:`}</h5>
      <p>{`11.1.1 Business Day means any day of the week, other than Saturday, Sunday and New Zealand public holidays.`}</p>
      <p>{`11.1.2 Choice means Choice Limited.`}</p>
      <p>{`11.1.3 Choice Platform means the mobile payment processing services used by Customers to purchase and pay for goods and/or services from Choice Registered Merchants.`}</p>
      <p>{`11.1.4 Choice Merchant means a retailer, business or service  provider who accepts payments for its goods and/or services through the Choice Platform and is registered as a merchant with Choice.`}</p>
      <p>{`11.1.5 Choice Registered User means a person who registers to use the Choice Platform.`}</p>
      <p>{`11.1.6 Merchant means a person who registers as a Choice Registered Merchant.`}</p>
      <p>{`11.1.7 Website means`}<a parentName="p" {...{
          "href": "https://www.choicetopay.com"
        }}>{`www.choicetopay.com`}</a></p>
      <p>{`11.1.8 You or Your is a reference to Users.`}</p>
      <title color="primary.main">Privacy Policy</title>
      <h2>{`1 We don’t share your information.`}</h2>
      <p>{`Choice Limited understands that your personal information is important to you and values your trust. We are committed to respecting your privacy and personal information and aim to be upfront and clear as to how we deal with your personal information.`}</p>
      <h2>{`2 You can’t be identified.`}</h2>
      <p>{`This Privacy Policy sets out how we collect, use, manage, and store personal information in accordance with the Privacy Act 1993 (including the Privacy Principles), and where applicable, personal data under the General Data Protection Regulation (GDPR).`}</p>
      <h2>{`3 We constantly improve.`}</h2>
      <p>{`We may change this Privacy Policy at any time. Any changes will be effective when a notice of change is posted on our website.`}</p>
      <h2>{`4 Choice is just a group of people working to make the world a better place.`}</h2>
      <p>{`In this Privacy Policy, we, us and our mean Choice Limited, and references to including and includes means including without limitation.`}</p>
      <h2>{`5 Choice will always be free to use.`}</h2>
      <p>{`We are committed to creating a product that generates tangible social impact as you use it. We are building a faster, fairer way for merchants to save money while receiving payments and directing half of that reduced fee to charity. This will never cost Choice users anything.`}</p>
      <h2>{`6 What information we collect`}</h2>
      <p>{`6.1 We may collect and hold your personal information:`}</p>
      <p>{`6.1.1 Provided by you, when you register to use our services;`}</p>
      <p>{`6.1.2 Collected from third parties, such as banks, In each case, to enable us to provide our services to you.`}</p>
      <h2>{`7 How we collect your information`}</h2>
      <p>{`7.1 The personal information we may collect includes:`}</p>
      <p>{`7.1.1 Information you give us, such as:`}</p>
      <p>{`a. your name;`}</p>
      <p>{`b. your contact information, including your email address;`}</p>
      <p>{`c. your bank account details; and`}</p>
      <p>{`d. your preferred charities`}</p>
      <p>{`7.1.2 Collected from third parties, such as banks, In each case, to enable us to provide our services to you.`}</p>
      <p>{`a. your location;`}</p>
      <p>{`b. your computer and/or network details;`}</p>
      <p>{`c. your browsing activity on our website;`}</p>
      <p>{`d. your transaction information; and`}</p>
      <p>{`7.1.3 Information collected from third parties to enable us to provide services to you, such as:`}</p>
      <p>{`a. publicly available information;`}</p>
      <h2>{`8 How and why we use your information`}</h2>
      <p>{`8.1 You agree that we may use your personal information for the following purposes:`}</p>
      <p>{`8.1.1 To deliver the services to you;`}</p>
      <p>{`8.1.2 To obtain your consent to using the services;`}</p>
      <p>{`8.1.3 To contact you and/or provide you with information about the services`}</p>
      <p>{`8.1.4 To verify your identity;`}</p>
      <p>{`8.1.5 Communicating with third party service providers who assist us with functions such as fraud prevention, technology and legal services;`}</p>
      <p>{`8.1.6 To fulfil our legal and regulatory requirements;`}</p>
      <p>{`8.1.7 To send you promotional, marketing or other advertising material about our services`}</p>
      <p>{`8.1.8 For any other use that you authorise.`}</p>
      <p>{`8.2 You may choose not to provide personal information to us; however this may result in you being unable to use our services.`}</p>
      <p>{`8.3 In relation to promotional and marketing communications, we will always provide you with a simple means of opting out of receiving such information in the future (for example, by clicking “unsubscribe” in email communications from us), or you can contact us directly.`}</p>
      <h2>{`9 Why we may disclose your personal information, and to whom`}</h2>
      <p>{`9.1 We may disclose your personal information to third parties:`}</p>
      <p>{`9.1.1 For the purposes set out in clause 3;`}</p>
      <p>{`9.1.2 If we are required or authorised by law to disclose the information;`}</p>
      <p>{`9.1.3 If it is necessary to facilitate any sale or restructure of our business;`}</p>
      <p>{`9.1.4 To our service providers, developers and advertisers, to enable us to provide the services;`}</p>
      <p>{`9.1.5 To promote and market related products and services to you; or`}</p>
      <p>{`9.1.6 If otherwise authorised by you.`}</p>
      <p>{`9.2 We take reasonable steps to ensure that third parties keep your personal information that they may receive from us confidential.`}</p>
      <h2>{`10 How we store your information`}</h2>
      <p>{`10.1 We take reasonable measures to protect your personal information and to ensure your personal information may only be accessed by authorised persons. We transmit all your data securely using SSL encryption. All data is stored in a secure data centre.`}</p>
      <p>{`10.2 We use technology called cookies when you use our website. A cookie is a piece of information that our web server will send to your machine. The cookie is stored on your hard drive or in memory but it does not give us information about your computer. We use cookies to learn more about the way you use our website and to help us improve your use of our website and your online experience and ensure your login details are saved. You are able to configure your web browser to accept or reject all cookies, or to notify you when a cookie is sent (please refer to your browser instructions to learn more about this). If you disable the use of cookies on your web browser or remove or reject specific cookies from our website, then you may not be able to use all the services or webpages on our website.`}</p>
      <p>{`10.3 We may collect information from you when you access and use our website, to help us better understand our website users and their browsing habits. This may include your IP address, browser type, operating system, referral URLs, device information, pages visited, links clicked, user interactions and search terms.`}</p>
      <p>{`10.4 You are personally responsible for ensuring the security of your user name, email address, password, profile information and bank details. We recommend you do not disclose your user registration details to any third parties. If you choose to share your personal information, including registration details, with a third party you are responsible for any actions taken with this information and access.`}</p>
      <h2>{`11 How you can manage your information`}</h2>
      <p>{`11.1 You can access, and request the correction of, any of your personal information at any time. You can also ask us to delete your personal information, however this may impact on your ability to use our services.`}</p>
      <p>{`11.2 If we no longer need to hold your personal information for the purposes for which it was collected, and no applicable law requires us to retain that information, we will take reasonable steps to securely destroy and dispose of your information.`}</p>
      <h2>{`12 How you can ask a question or make a complaint`}</h2>
      <p>{`12.1 If you wish to contact us in relation to this Privacy Policy, your personal information or to make a complaint about our compliance with the Privacy Act, please contact our Privacy Officer:`}</p>
      <Card borderRadius={10} borderColor="dark" boxShadow="0 2px 16px rgba(0, 0, 0, 0.25)" px={4} width={[1, 1, 2 / 3]} alignSelf="right" mdxType="Card">
        <Text mdxType="Text">Name:  &nbsp;Privacy Officer</Text>
  <Text css={{
          display: 'inline-block'
        }} mdxType="Text">Email:</Text>
  <Text css={{
          display: 'inline-block'
        }} color="primary.main" mdxType="Text">
          <p><a parentName="p" {...{
              "href": "mailto:info@choicetopay.com"
            }}>{`info@choicetopay.com`}</a></p>
  </Text>
  <Text mdxType="Text">Address:  &nbsp;1/101 Pakenham Street West, Auckland Central, Auckland 1010, New Zealand</Text>
  <Text css={{
          display: 'inline-block'
        }} mdxType="Text">Website:</Text>
  <Text css={{
          display: 'inline-block'
        }} color="primary.main" mdxType="Text">
          <p><a parentName="p" {...{
              "href": "https://www.choicetopay.com"
            }}>{` choicetopay.com`}</a></p>
  </Text>
  <Text mdxType="Text">Phone:  &nbsp;+64 21 656786</Text>
      </Card>
      <p>{`12.2 If you are not happy with our response to your query or complaint,
you can contact the Office of the Privacy Commissioner at`}<a parentName="p" {...{
          "href": "https://www.privacy.org.nz"
        }}>{`www.privacy.org.nz.`}</a></p>
      <h2>{`13 GDPR and your personal data`}</h2>
      <p>{`13.1 If you are a resident of the European Union, you also have the following rights in respect of your personal data (defined below) under the General Data Protection Regulation (EU) 2016/679 (GDPR):`}</p>
      <p>{`13.1.1 Right to erasure – if requested by you, we will delete all of your personal information stored by us, unless we are required by law to keep it.`}</p>
      <p>{`13.1.2 Right to restriction of processing – in certain circumstances (for example, you believe that your personal data is inaccurate or your data has been unlawfully processed), you have the right to request that the processing of your personal data is restricted.`}</p>
      <p>{`13.1.3 Right to objection of processing – you have the right to object to your personal information being processed, including for direct marketing purposes.`}</p>
      <p>{`13.1.4 Right to portability – you may require that the personal information you have provided us is provided to you in a structured, commonly used and machine-readable format, and is transferred to another data controller.`}</p>
      <p>{`13.1.5 Right to object to automated decision making – You have the right to not be subject to a decision based solely on automated processing, including profiling.`}</p>
      <p>{`13.2 Personal data means any information relating to an identified or identifiable natural person (data subject); an identifiable person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that person.`}</p>
      <p>{`13.3 In accordance with our obligations under the GDPR, if a personal data breach occurs, we will notify the relevant European Union supervisory authority without undue delay and, where feasible, within 72 hours of becoming aware of the breach occurring (unless the personal data breach is unlikely to result in a risk to the rights and freedoms of natural persons). If the personal data breach is likely to result in a high risk to the rights and freedoms of natural persons, we will also notify the data subject of the breach, without undue delay. You have the right to lodge a complaint in respect of our treatment of your personal data with a supervisory authority.`}</p>
    </SectionBox>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      